<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">申請 - 変更履歴</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                v-model="search.year"
                @change="regetList(search.year)"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="apptypeList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="searchMiddle inlineBlock searchSpace">
            <div>文理区分</div>
            <div>
              <b-form-select
                :options="bunriOption"
                v-model="search.bunri">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                  <!-- <option :value="null">区分なし</option> -->
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請者名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                v-model="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="tableWrap" class="contentsWidth mt-2">
        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppChangeLogTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/cms/applications/change/log/view/'+row.item.id">
              詳細
            </b-link>
          </template>
        </b-table>
      </div>


      <div class="contentsWidth mt-2" v-if="totalRows > 0">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'CmsApplicationsHistoryList',
  data() {
    return {
      search: {
        name: '',
        series: '',
        bunri: '',
        year: '',
        code: '',
        status: '',
      },
      dataChangeStatusList: CONST_STATUS.CHANGE_DATA_STATUS_TEXT,
      status: CONST_STATUS.APP_STATUS,
      statusList: CONST_STATUS.CMS_APP_STATUS_OPTION_4_LIST,
      dbAppList: [],
      header: [
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'institution', label: '所属', sortable: true },
        { key: 'year', label: '年度', sortable: true },
        { key: 'apptype', label: '助成プログラム', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'bunri', label: '文理区分', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      seriesList: [],
      yearList: [],
      sortBy: 'code',
      sortDesc: true,
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const apptypeSort = search.series === '' || lineData.apptype === search.series || (search.series === '社会的文化的諸活動助成' && lineData.apptype === '社会的・文化的諸活動助成');
      const yearBunri = search.bunri === '' || lineData.bunri === search.bunri;
      const codeSort = search.code === '' || (lineData.code && lineData.code.includes(search.code));
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      return nameSort && apptypeSort && codeSort && statusSort && yearBunri;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.$store.commit('cmsApplicationSearch/initSelectedAppid');
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsApplicationSearch/setFilterdIdList', filteredItems);
    },
    async fetchList(year) {
      const param = { year };
      const response = await api.send('/api/cms/applications/change/log/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbAppList = response.data.list;
      this.seriesList = response.data.seriesList;
      this.yearList = response.data.yearList;
      this.$set(this.search, 'year', response.data.selectedYear);
      this.totalRows = this.dbAppList.length;
      // this.$store.commit('cmsApplicationSearch/setFilterdIdList', this.dbAppList);
    },
    async regetList(year) {
      this.$store.dispatch('page/onWaiting');
      if (year !== null && year !== '' && year !== 'all') {
        if (year < 2025) {
          if (this.search.series === '学術調査研究助成') {
            this.$set(this.search, 'series', '調査研究助成');
          }
          if (this.search.series === 'デジタルイノベーション社会実装助成') {
            this.$set(this.search, 'series', '目的型調査研究助成');
          }
        } else {
          if (this.search.series === '目的型諸活動助成') {
            this.$set(this.search, 'series', '');
          }
          if (this.search.series === '調査研究助成') {
            this.$set(this.search, 'series', '学術調査研究助成');
          }
          if (this.search.series === '目的型調査研究助成') {
            this.$set(this.search, 'series', 'デジタルイノベーション社会実装助成');
          }
        }
      }
      await this.fetchList(year);
      this.$store.dispatch('page/offWaiting');
    },
  },
  computed: {
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    adjustedApptypeData() {
      let optionDbData = this.seriesList;
      if (this.search.year === 'all') {
        optionDbData = [];
        this.seriesList.forEach((series) => {
          optionDbData.push(series);
          if (series.id === 2) {
            optionDbData.push({ id: series.id, name: '調査研究助成' });
          }
          if (series.id === 8) {
            optionDbData.push({ id: series.id, name: '目的型調査研究助成' });
          }
        });
      }

      return optionDbData;
    },
    apptypeList() {
      const filterSeriesList = [];
      this.adjustedApptypeData.forEach((data) => {
        if ((this.search.year !== null && (this.search.year === 'all' || this.search.year < 2025)) || data.id !== 9) {
          filterSeriesList.push(data);
        }
      });
      const appTypeList = filterSeriesList.map((series) => {
        if (this.search.year !== null) {
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 2) {
            return { value: '調査研究助成', text: '調査研究助成', id: 2 };
          }
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 8) {
            return { value: '目的型調査研究助成', text: '目的型調査研究助成', id: 8 };
          }
        }
        return { value: series.name, text: series.name, id: series.id };
      });
      return appTypeList;
    },
    bunriOption() {
      return ['文', '理'];
    },
    appList() {
      const appList = this.dbAppList.map((application) => {
        let bunri = '';
        if (application.bunri === 1 || application.bunri === 2) {
          bunri = this.bunriOption[application.bunri - 1];
        }
        let name = '';
        let kana = '';
        if (application.ans_name && application.ans_kana) {
          application.ans_name.forEach((data) => {
            name += `${data.field_answer_text} `;
          });
          application.ans_kana.forEach((data) => {
            kana += `${data.field_answer_text} `;
          });
        }
        let institution = '';
        if ((application.apptype.name === '国際会議開催助成' || application.apptype.name === '社会的・文化的諸活動助成') && application.ans_apply_org.length > 0) {
          const applyOrg = application.ans_apply_org[0];
          institution = applyOrg.field_answer_text;
        } else {
          institution = application.university_name;
          if (!institution) {
            // eslint-disable-next-line
            institution = application.institution;
          }
        }
        return {
          id: application.id,
          apptypeId: application.application_type_id,
          apptype: application.apptype.name,
          name,
          kana,
          bunri,
          year: application.apptype.year,
          code: application.code,
          status: application.status,
          statusText: CONST_STATUS.APP_STATUS_TEXTS[application.status],
          seriesId: application.series.series_id,
          institution,
        };
      });
      return appList;
    },
  },
  async created() {
    this.$store.dispatch('page/onLoading', 'CmsTop');
    const { year } = this.$route.params;
    await this.fetchList(year);
    this.$store.dispatch('page/offLoading', 'CmsTop');
  },
};
</script>
<style>
  .cmsAppChangeLogTable thead th:nth-of-type(0) {
    width: 140px !important;
  }
</style>
<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
